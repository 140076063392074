import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { DxNumberBoxModule, DxPopoverModule } from 'devextreme-angular';
import { NumberboxLabelGestioComponent } from './numberbox-label/cmp-numberbox-label.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DxNumberBoxModule,
        DxPopoverModule,
        FlexLayoutModule,
    ],
    declarations: [NumberboxLabelGestioComponent],
    exports: [NumberboxLabelGestioComponent],
})
export class NumberboxsComponentsGestioModule {}
