import {
    Component,
    ElementRef,
    Input,
    ViewChild
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { StateModeEnum } from 'src/app/app.enums';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CmpTitleAreaGestioComponent } from '../title-area/cmp-title-area.component';

@Component({
    selector: 'gestio-cmp-detail-section-navigation',
    templateUrl: './cmp-detail-section-navigation.component.html',
    styleUrls: ['./cmp-detail-section-navigation.component.scss'],
})
export class CmpDetailSectionNavigationGestioComponent {
    @Input() navigationItems = [];
    @Input() originalNavigationItems = [];
    @Input() sideDetailNavigationRef!: ElementRef;
    @Input() state$: BehaviorSubject<StateModeEnum>;
    @Input() titleAreaContent: CmpTitleAreaGestioComponent;
    @ViewChild('navigationFixedElement') navigationFixedElementRef!: ElementRef;

    isInsertEditMode: boolean = true;
    showDetailSectionNavigationBar: boolean = false;
    selectedSection: string = '';

    // private observer!: IntersectionObserver;
    private stateSubscription: Subscription;

    constructor(
        // private cdr: ChangeDetectorRef,
        public authService: AuthService
    ) {}

    ngOnInit() {
        if (this.state$) {
            this.stateSubscription = this.state$.subscribe((r) => {
                this.isInsertEditMode =
                    r == StateModeEnum.insert || r == StateModeEnum.edit;

                this.defineVisibleSections();
            });
        }

        this.selectedSection = this.navigationItems[0].scrollTo;
    }

    ngOnChanges() {
        this.defineVisibleSections();
    }

    ngAfterViewInit(): void {
        // this.observer = new IntersectionObserver(
        //     ([entry]) => {
        //         const isOutOfView = !entry.isIntersecting;
        //         if (this.showDetailSectionNavigationBar !== isOutOfView) {
        //             this.showDetailSectionNavigationBar = isOutOfView;
        //             if (this.showDetailSectionNavigationBar) {
        //                 this.scrollToOnFixed(this.selectedSection);
        //             }
        //             this.cdr.detectChanges();
        //         }
        //     },
        //     { threshold: 0 }
        // );
        // if (this.titleAreaContent) {
        //     this.observer.observe(this.titleAreaContent.getNativeElement().nativeElement);
        // }
    }

    ngOnDestroy(): void {
        if (this.state$) {
            this.stateSubscription.unsubscribe();
        }
        // if (this.observer) {
        //     this.observer.disconnect();
        // }
    }

    defineVisibleSections() {
        if (this.isInsertEditMode) {
            this.navigationItems = this.navigationItems.filter(
                (item) => !item.hideOnInsertEdit
            );
            this.selectedSection = this.navigationItems[0].scrollTo;
        } else {
            this.navigationItems = this.originalNavigationItems;
        }
    }

    scrollLeft() {
        const element = this.navigationFixedElementRef.nativeElement;
        element.scrollBy({ left: -100, behavior: 'smooth' });
    }

    scrollRight() {
        const element = this.navigationFixedElementRef.nativeElement;
        element.scrollBy({ left: 100, behavior: 'smooth' });
    }

    selectSection(scrollTo: string): void {
        this.selectedSection = scrollTo;
    }

    scrollToOnFixed(scrollTo: string): void {
        const container = this.navigationFixedElementRef.nativeElement;
        const element = container.querySelector(`#referencefixed-${scrollTo}`);
        if (element) {
            const elementRect = element.getBoundingClientRect();
            const containerRect = container.getBoundingClientRect();

            const offsetLeft = elementRect.left - containerRect.left;

            container.scrollBy({
                left:
                    offsetLeft -
                    container.clientWidth / 2 +
                    element.clientWidth / 2,
                behavior: 'smooth',
            });
        }
    }
}
