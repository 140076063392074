import { HttpParams } from '@angular/common/http';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import { LoadOptions } from 'devextreme/data/load_options';
import { Column } from 'devextreme/ui/data_grid';
import * as saveAs from 'file-saver';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import * as XLSX from 'xlsx';

import { StateModeEnum, UnidadeMedidaEnum } from './app.enums';

export class AppHelper {
    private static sessionGuid$: BehaviorSubject<string> =
        new BehaviorSubject<string>(AppHelper.createGuid());

    private static _fileNameResponse(res: Response): string {
        let f = 'DOWNLOAD';

        if (!res || !res.headers) {
            return f;
        }

        let contentDisposition = res.headers.get('Content-Disposition');
        if (!contentDisposition) {
            return f;
        }

        let r = contentDisposition
            .split(';')[1]
            .trim()
            .split('=')[1]
            .replace(/"/g, '');
        if (r && r.length > 1) {
            f = r;
        }

        return f;
    }
    public static saveFileResponse(res: Response | any): void {
        let f = this._fileNameResponse(res);
        saveAs.saveAs(res.body, f);
    }

    private static _fileNameXMLHttpRequest(res: any): string {
        let f = 'DOWNLOAD';

        if (!res) {
            return f;
        }

        let contentDisposition = res.getResponseHeader('Content-Disposition');
        if (!contentDisposition) {
            return f;
        }

        let r = contentDisposition
            .split(';')[1]
            .trim()
            .split('=')[1]
            .replace(/"/g, '');
        if (r && r.length > 1) {
            f = r;
        }

        return f;
    }
    public static saveFileXMLHttpRequest(res: XMLHttpRequest): void {
        let f = this._fileNameXMLHttpRequest(res);
        let b = res.response;
        saveAs.saveAs(b, f);
    }

    public static downloadFileFromUrl(url: string, filename: string): void {
        // console.log("DOWLOAD", url, filename);
        saveAs.saveAs(url, filename);
    }

    public static copyToClipboard(v: string): void {
        try {
            let nav: any;
            nav = window.navigator;

            nav.clipboard.writeText(v).then(
                function () {
                    /* clipboard successfully set */
                },
                function () {
                    /* clipboard write failed */
                    console.log('Error clipboard...');
                }
            );
        } catch (err) {
            console.log('Error copy to clipboard', err.message);
        }
    }

    public static isURL(value: any): boolean {
        var pattern =
            /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        return pattern.test(value);
    }

    public static hasValue(value: any) {
        if (value == null) return false;
        if (value == undefined) return false;
        return true;
    }

    public static strToNumber(value: any) {
        if (!value) return value;

        return value.replace(/\D/g, '');
    }
    public static strToDate(value: any): Date {
        if (!value) return value;

        if (value instanceof Date) {
            return value;
        }

        if (
            (typeof value === 'string' || value instanceof String) &&
            value.length > 9
        ) {
            let yy = +value.slice(0, 4);
            let mm = +value.slice(5, 7) - 1;
            let dd = +value.slice(8, 10);
            return new Date(yy, mm, dd);
        }
        return value;
    }
    public static strToDateTime(value: any) {
        if (!value) return value;

        if (
            (typeof value === 'string' || value instanceof String) &&
            value.length > 9
        ) {
            let yy = +value.slice(0, 4);
            let mm = +value.slice(5, 7) - 1;
            let dd = +value.slice(8, 10);

            let hh = +value.slice(11, 13);
            let MM = +value.slice(14, 16);
            let ss = +value.slice(17, 19);
            return new Date(yy, mm, dd, hh, MM, ss);
        }
        return value;
    }
    public static dateToStr(value: any) {
        if (!value) return value;

        if (value instanceof Date) {
            return moment(value).format('YYYYMMDD');
        }
        return value;
    }
    public static firstDayOfMonth(value?: Date): Date {
        let dt = new Date();
        if (!value) {
            dt = moment(new Date()).startOf('month').startOf('date').toDate();
        } else {
            dt = moment(value).startOf('month').startOf('date').toDate();
        }
        return dt;
    }
    public static lastDayOfMonth(value?: Date): Date {
        let dt = new Date();
        if (!value) {
            dt = moment(new Date()).endOf('month').startOf('date').toDate();
        } else {
            dt = moment(value).endOf('month').startOf('date').toDate();
        }
        return dt;
    }

    public static getYear(value?: Date): number {
        if (!value) {
            return 0;
        }
        try {
            let r = value.getFullYear();
            return r;
        } catch {
            return -10;
        }
    }
    public static getMonth(value?: Date): number {
        if (!value) {
            return 0;
        }
        try {
            let r = value.getMonth() + 1;
            return r;
        } catch {
            return -11;
        }
    }
    public static currentDate(): Date {
        return new Date();
    }
    public static currentYear(): number {
        let d = new Date();
        return d.getFullYear();
    }
    public static currentMonth(): number {
        let d = new Date();
        let r = d.getMonth() + 1;
        return r;
    }
    public static currentDay(): number {
        let d = new Date();
        let r = d.getDate();
        return r;
    }
    public static formatDate(value: any): string {
        if (!value) return value;

        var dt = this.strToDate(value);
        if (dt instanceof Date) {
            var format = dt.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
            return format;
        }

        return value;
    }
    public static formatDateUS(value: any): string {
        if (!value) return value;

        var dt = this.strToDate(value);
        if (dt instanceof Date) {
            const dia = dt.getDate();
            const ano = dt.getFullYear();
            const mes = dt.getMonth() + 1;
            return ano + '/' + mes + '/' + dia;
        }

        return value;
    }
    public static toDateUTC(value: any): Date {
        if (!value) return value;

        if (value instanceof Date) {
            const dtUTCStr = value.toUTCString();
            return new Date(dtUTCStr);
        }

        return value;
    }

    public static removeHtmlTags(value: any): string {
        if (!value) return value;

        return value.replace(/(<([^>]+)>)/gi, '');
    }

    public static round(value: number, dec: number = 2): number {
        let r = Number(value).toFixed(dec);
        return Number(r);
    }
    public static truncate(value: number): number {
        const numberWith2Decimals = Number(
            value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
        );
        return numberWith2Decimals;
    }

    public static numberToFmtStr(value: number): string {
        if (!value) return '0,00';

        let r = Number(value).toFixed(2);

        r = r.replace(/\B(?=(\d{3})+(?!\d))/g, '@');
        r = r.replace(/\./g, ',');
        r = r.replace(/\@/g, '.');
        return r;
    }
    public static incDay(dt: Date, i: number = 1) {
        let d = moment(dt);
        return d.add(i, 'd').toDate();
    }
    public static incDayFrowNow(i: number = 1) {
        const dt = new Date();
        dt.setHours(0, 0, 0, 0);
        let d = moment(dt);
        return d.add(i, 'd').toDate();
    }
    public static incMonth(dt: Date, i: number = 1): Date {
        let d = moment(dt);
        return d.add(i, 'M').toDate();
    }
    public static incMinutes(dt: Date, min: number = 1): Date {
        var d = new Date(dt);
        d.setMinutes(d.getMinutes() + min);
        return d;
    }
    public static today(): Date {
        const dt = new Date(
            this.currentYear(),
            this.currentMonth() - 1,
            this.currentDay()
        );
        dt.setHours(0, 0, 0, 0);
        return dt;
    }
    public static getMinutesBetweenDates(
        startDate: Date,
        endDate: Date
    ): number {
        var diff = endDate.getTime() - startDate.getTime();
        return _.round(diff / 60000, 0);
    }
    public static dateDifference(startDate: Date, endDate: Date) {
        if (!startDate || !endDate) {
            return null;
        }
        if (!this.dateIsValid(startDate)) {
            return null;
        }
        if (!this.dateIsValid(endDate)) {
            return null;
        }

        const diffInMilliseconds = Math.abs(
            endDate.getTime() - startDate.getTime()
        );

        const diffInSeconds = diffInMilliseconds / 1000;
        const diffInMinutes = diffInSeconds / 60;
        const diffInHours = diffInMinutes / 60;
        const diffInDays = this.round(diffInHours / 24, 0);
        const diffInMonths = diffInDays / 30.436875;
        const diffInYears = diffInMonths / 12;
        return {
            diffInMilliseconds,
            diffInSeconds,
            diffInMinutes,
            diffInHours,
            diffInDays,
            diffInMonths,
            diffInYears,
        };
    }
    public static dateIsValid(date: any) {
        return date instanceof Date && !isNaN(date as any);
    }

    public static sumBy(objs: any[], columnName: string): number {
        if (!objs || objs.length == 0) {
            return 0;
        }
        const r = _.sumBy(objs, columnName);
        return _.round(r, 2);
    }
    public static maxBy(objs: any[], columnName: string): number {
        if (!objs || objs.length == 0) {
            return 0;
        }
        const r = _.maxBy(objs, columnName);
        return r[columnName];
    }

    public static random(max: number): number {
        return Math.floor(Math.random() * max);
    }

    public static growlMessageSuccess(msg: string, title: string = 'Atenção') {
        return { severity: 'success', summary: title, detail: msg };
    }
    public static growlMessageWarn(msg: string, title: string = 'Atenção') {
        return { severity: 'warn', summary: title, detail: msg };
    }
    public static growlMessageError(msg: string, title: string = 'Atenção') {
        return { severity: 'error', summary: title, detail: msg };
    }
    public static growlMessageErrorFmt(err: any, title: string = 'Atenção') {
        let msg = 'Ocorreu um erro';
        if (err && err.errorMessage) {
            msg = err.errorMessage;
        }
        return { severity: 'error', summary: title, detail: msg };
    }

    public static adjustText(value: string, maxlength: number): string {
        if (!value) {
            return value;
        }
        return value.substr(0, maxlength);
    }

    public static setCtrlEnable(ctrl: FormControl, b: boolean = true): void {
        if (!ctrl) {
            return;
        }

        if (b) {
            if (ctrl.disabled) {
                ctrl.enable();
            }
        } else {
            if (ctrl.enabled) {
                ctrl.disable();
            }
        }
    }
    public static setCtrlValue(
        ctrl: FormControl,
        value: any,
        markAsTouched: boolean = false
    ): void {
        if (!ctrl) {
            return;
        }

        const r = ctrl.value;
        if (r != value) {
            ctrl.setValue(value);

            if (markAsTouched) {
                ctrl.markAsTouched();
            }
        }
    }
    public static setCtrlMarkAsTouched(ctrl: FormControl): void {
        if (!ctrl) {
            return;
        }

        ctrl.markAsTouched();
    }
    public static setCtrlMarkAsUntouched(ctrl: FormControl): void {
        if (!ctrl) {
            return;
        }

        ctrl.markAsUntouched();
    }
    public static markControlsDirtyAndTouched(group: FormGroup): void {
        Object.keys(group.controls).forEach((key: string) => {
            const abstractControl = group.controls[key];

            if (abstractControl instanceof FormGroup) {
                this.markControlsDirtyAndTouched(abstractControl);
            } else {
                abstractControl.markAsDirty();
                abstractControl.markAsTouched();
            }
        });
    }
    public static logControlsInvalid(group: FormGroup): void {
        console.log('### logControlsInvalid ###', group);
        Object.keys(group.controls).forEach((field) => {
            const control = group.get(field);
            if (control.invalid) {
                console.log(
                    `### Campo: [${field}] | Valor: [${control.value}] inválido. ###`
                );
            }
        });
    }

    public static isInsertMode(state: StateModeEnum): boolean {
        return state == StateModeEnum.insert;
    }
    public static isEditMode(state: StateModeEnum): boolean {
        return state == StateModeEnum.edit;
    }
    public static isInsertEditMode(state: StateModeEnum): boolean {
        return state == StateModeEnum.insert || state == StateModeEnum.edit;
    }
    public static isModified(state: StateModeEnum): boolean {
        return state == StateModeEnum.modified;
    }
    public static isBrowseMode(state: StateModeEnum): boolean {
        return state == StateModeEnum.browse;
    }
    public static isSelectedMode(state: StateModeEnum): boolean {
        return state == StateModeEnum.selected;
    }
    public static isBrowseSelectedMode(state: StateModeEnum): boolean {
        return state == StateModeEnum.browse || state == StateModeEnum.selected;
    }
    public static isUndefinedMode(state: StateModeEnum): boolean {
        return state == StateModeEnum.undefined;
    }

    public static messageNumberOfRecordsExceeded(): string {
        return `Não foi possível retornar todos os registros.
        Por favor, aplique os filtros disponíveis para diminuir a quantidades de registros selecionados.`;
    }

    public static createGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
            /[xy]/g,
            function (c) {
                var r = (Math.random() * 16) | 0,
                    v = c === 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            }
        );
    }

    public static saveDatagridState(gridName: string, state: any) {
        if (!gridName || !state) {
            return;
        }

        const sessionInfo = { sessionGuid: this.sessionGuid$.value };
        const stateSession = { ...state, ...sessionInfo };

        sessionStorage.setItem(gridName, JSON.stringify(stateSession));
        localStorage.setItem(gridName, JSON.stringify(state));
    }
    public static loadDatagridState(gridName: string) {
        if (!gridName) {
            return;
        }

        const stateSession = sessionStorage.getItem(gridName);
        if (stateSession) {
            let _state = JSON.parse(stateSession);

            if (
                _state.sessionGuid &&
                _state.sessionGuid != this.sessionGuid$.value
            ) {
                for (var i = 0; i < _state.columns.length; i++) {
                    _state.columns[i].filterValue = null;
                    _state.columns[i].filterValues = null;
                }
            }

            return _state;
        }

        const stateLocal = localStorage.getItem(gridName);
        if (stateLocal) {
            let _state = JSON.parse(stateLocal);

            _state.pageIndex = 0;
            for (var i = 0; i < _state.columns.length; i++) {
                _state.columns[i].filterValue = null;
                _state.columns[i].filterValues = null;
            }
            return _state;
        }
        return null;
    }

    public static existsGroupInDatagridState(gridName: string): boolean {
        var _state = this.loadDatagridState(gridName);
        if (_state && _state.columns) {
            return _state.columns.some((e: any) => e.groupIndex >= 0);
        }
        return false;
    }

    public static httpParamsFromLoadOptions(
        loadOptions: LoadOptions
    ): HttpParams {
        if (!loadOptions) {
            return new HttpParams();
        }

        const skip = loadOptions.skip ? loadOptions.skip : 0;
        const take = loadOptions.take ? loadOptions.take : 20;
        const filter = loadOptions.filter;
        const sort = loadOptions.sort;

        let params = new HttpParams();
        params = params.append(`skip`, skip.toString());
        params = params.append(`take`, take.toString());
        if (sort) {
            const [sortParams] = sort;
            params = params.append(`orderBy`, sortParams.selector);
            if (sortParams.desc) {
                params = params.append(`desc`, sortParams.desc.toString());
            }
        }

        if (filter) {
            let filterColumns = [];
            filter.forEach((r: any) => {
                if (Array.isArray(r)) {
                    const [first, _, second] = r;
                    if (Array.isArray(first) && Array.isArray(second)) {
                        filterColumns.push(first);
                        filterColumns.push(second);
                    } else {
                        filterColumns.push(r);
                    }
                }
            });

            if (filterColumns.length == 0) {
                filterColumns = [filter];
            }

            const filterParams = filterColumns.map((r: any) => {
                if (r && r.length == 3) {
                    const [Field, Operation, Value] = r;
                    return {
                        Field,
                        Operation,
                        Value,
                    };
                }
            });

            params = params.append(`filters`, JSON.stringify(filterParams));
        }

        return params;
    }

    public static exportToExcel(
        exportData: any,
        fileName: string,
        worksheetName: string = 'Gestio'
    ) {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);

        const workbook: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);

        const excelBuffer: any = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        const blob = new Blob([excelBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, fileName);
    }

    public static codigoDeUnidade_POR_AREA(codunid: number): boolean {
        if (!codunid) {
            return false;
        }

        return (
            this.codigoDeUnidade_CENTIMETRO(codunid) ||
            this.codigoDeUnidade_METRO(codunid)
        );
    }

    public static codigoDeUnidade_CENTIMETRO(codunid: number): boolean {
        // 7	CENTIMETRO
        // 67	CENTIMETRO QUADRADO

        if (!codunid) {
            return false;
        }

        return codunid == 7 || codunid == 67;
    }

    public static codigoDeUnidade_METRO(codunid: number): boolean {
        // 3	METRO
        // 5	METRO QUADRADO

        if (!codunid) {
            return false;
        }

        return codunid == 3 || codunid == 5;
    }

    public static validarUnidadeDeMedidaArea(unid: string): boolean {
        if (!unid) {
            return false;
        }

        return (
            unid == UnidadeMedidaEnum.METRO ||
            unid == UnidadeMedidaEnum.CENTIMETRO
        );
    }

    public static calcularAreaTotalM2(
        unid: string,
        largura: number,
        altura: number
    ): number {
        if (unid == UnidadeMedidaEnum.QTDE) {
            return 0;
        }

        if (unid == UnidadeMedidaEnum.METRO) {
            const areaM2 = largura * altura;

            return AppHelper.round(areaM2, 6);
        }

        if (unid == UnidadeMedidaEnum.CENTIMETRO) {
            const larguraM2 = AppHelper.round(largura / 100, 6);
            const alturaM2 = AppHelper.round(altura / 100, 6);
            const areaM2 = larguraM2 * alturaM2;

            return AppHelper.round(areaM2, 6);
        }

        return 0;
    }

    public static calculateFilterExpression(
        this: Column | any,
        filterValue: any,
        selectedFilterOperation: string,
        column: Column
    ) {
        const normalizeString = (str: string) =>
            str
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase();
        const resolveDataField = (obj: any, path: string) => {
            return path.split('.').reduce((acc, part) => acc && acc[part], obj);
        };

        if (filterValue && column && column.dataField) {
            if (selectedFilterOperation === 'contains') {
                const filterValues = normalizeString(
                    filterValue.toString()
                ).split(' ');
                return (data: any) => {
                    const columnValue = resolveDataField(
                        data,
                        column.dataField
                    );
                    if (typeof columnValue === 'string') {
                        const normalizedValue = normalizeString(columnValue);
                        return filterValues.every((filterValue: string) => {
                            return normalizedValue.includes(filterValue);
                        });
                    }
                    return false;
                };
            } else if (selectedFilterOperation === '=') {
                return (data: any) => {
                    const columnValue = resolveDataField(
                        data,
                        column.dataField
                    );
                    return columnValue === filterValue;
                };
            } else if (selectedFilterOperation === '<>') {
                return (data: any) => {
                    const columnValue = resolveDataField(
                        data,
                        column.dataField
                    );
                    return columnValue !== filterValue;
                };
            } else if (selectedFilterOperation === 'startswith') {
                return (data: any) => {
                    const columnValue = resolveDataField(
                        data,
                        column.dataField
                    );
                    if (typeof columnValue === 'string') {
                        return columnValue
                            .toLowerCase()
                            .startsWith(filterValue.toLowerCase());
                    }
                    return false;
                };
            } else if (selectedFilterOperation === 'endswith') {
                return (data: any) => {
                    const columnValue = resolveDataField(
                        data,
                        column.dataField
                    );
                    if (typeof columnValue === 'string') {
                        return columnValue
                            .toLowerCase()
                            .endsWith(filterValue.toLowerCase());
                    }
                    return false;
                };
            } else if (selectedFilterOperation === 'notcontains') {
                return (data: any) => {
                    const columnValue = resolveDataField(
                        data,
                        column.dataField
                    );
                    if (typeof columnValue === 'string') {
                        return !normalizeString(columnValue).includes(
                            normalizeString(filterValue)
                        );
                    }
                    return false;
                };
            }
        }
        return null;
    }

    public static customEmailValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return null;
            }

            const emailRegex =
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return emailRegex.test(control.value)
                ? null
                : { errorInvalid: true };
        };
    }
}
