import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Route,
    RouterStateSnapshot,
} from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { first, mergeMap, take } from 'rxjs/operators';
import { AppRoutingService } from 'src/app/app-routing.service';
import { AuthService } from '../services/auth.service';
@Injectable()
export class DashboardGuard implements CanActivate, CanLoad {
    constructor(
        private _authService: AuthService,
        private _routingService: AppRoutingService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        return this._checkAcess(state.url);
    }

    canLoad(route: Route): Observable<boolean> | boolean {
        return this._checkAcess(route.path || '');
    }

    private _checkAcess(url: string): Observable<boolean> | boolean {
        return combineLatest([
            this._authService.responsavelCompras$.pipe(take(1)),
            this._authService.moduloCompras$.pipe(take(1)),
            this._authService.moduloEstoque$.pipe(take(1)),
            this._authService.moduloVendas$.pipe(take(1)),
            this._authService.moduloFinanceiro$.pipe(take(1)),
        ]).pipe(
            mergeMap(([rc, c, e, v, f]) => {
                if (url.includes('dashboard/compras-gerencial')) {
                    if (!c && !rc) {
                        this._routingService.sistemaAvisoSemPermissao();
                        return of(false);
                    }
                    return of(true);
                }

                if (url.includes('dashboard/compras-operacional')) {
                    if (!c) {
                        this._routingService.sistemaAvisoSemPermissao();
                        return of(false);
                    }
                    return of(true);
                }

                if (url.includes('dashboard/estoque')) {
                    if (!e) {
                        this._routingService.sistemaAvisoSemPermissao();
                        return of(false);
                    }
                    return of(true);
                }

                if (url.includes('dashboard/vendas')) {
                    if (!v) {
                        this._routingService.sistemaAvisoSemPermissao();
                        return of(false);
                    }
                    return of(true);
                }

                if (url.includes('dashboard/financeiro')) {
                    if (!f) {
                        this._routingService.sistemaAvisoSemPermissao();
                        return of(false);
                    }
                    return of(true);
                }
                return of(false);
            }),
            first()
        );
    }
}
